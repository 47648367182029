import {makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    container: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    title: {
      marginBottom: theme.spacing(1)
    },
    cardContainer: {
      marginBottom: theme.spacing(3)
    },
    card: {
      border: '1.5px solid #e0e0e0',
      padding: theme.spacing(0)
    },
    cardContentRoot: {
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px !important`,
      '&:last-child': {
        padding: theme.spacing(0)
      }
    },
    cardContent: {
      padding: theme.spacing(0)
    },
    cardContentText: {
      fontWeight: 500
    },
    iconButton: {
      padding: theme.spacing(0)
    },
  }
));