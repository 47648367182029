import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  denomLabel: {
    fontSize: '1rem',
    margin: 'auto',

    color: theme.palette.primary.dark,
  },
  peripheralDivider: {
    marginBottom: '15px'
  },
  denomGrid: {
    maxWidth: 400,
  },
  progress: {
    display: 'flex',
    float: 'left',
    marginLeft: '10px',
  },
  denomAcceptedTitle: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '20px',
    color: theme.palette.primary.light,
  },
  submitBtn: {
    marginBottom: '12px',
    flex: 0.33
  },
  bnrAlert: {
    width: "95%",
  },
  peripheralBox: {
    padding: '10px'
  },
  peripheralsDrawer: {
    display: "inline-block",
    maxWidth: "435px",
  },
  cancelBtn: {
    margin: theme.spacing(1),
    float:"right",
  },
}));
