import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { FormControl, Grid, InputLabel, MenuItem, Select } from "@material-ui/core";
import clsx from "clsx";
import _ from "lodash";
import { INTERFACE_BOARD_TYPE } from "../../../../../constants";

const ProxReaderSettings = ({ peripheral, updatePeripheralSettings }) => {
	const settingsClone = _.cloneDeep(peripheral?.settings);

	const { control, setValue, watch, getValues } = useForm({
		mode: 'onChange',
		defaultValues: {
      interfaceBoardType: settingsClone?.interfaceBoardType ?? 1
		},
	});

	useEffect(() => {
		const subscription = watch(() => {
			updatePeripheralSettings(getValues(), peripheral.peripheralID)
		});

		return () => subscription.unsubscribe();
	}, [watch]);

  const onBoardTypeChange = (settingName, value) => {
    setValue(settingName, value);
  }

	return (
		<Grid container spacing={4}>
			<Grid item xs={12}>
				<Controller
          name="interfaceBoardType"
					control={control}
					render={({ field }) => (
						<FormControl fullWidth FormLabel="Interface Board Type" variant="outlined">
							<InputLabel id="interfaceBoardLabel" className={clsx("dropdown-label")}>Interface Board Type</InputLabel>
							<Select
								{...field}
								displayEmpty
								data-id="interface-board-type-dropdown"
								className={clsx("interface-board-dropdown")}
								variant="outlined"
								fullWidth
								labelId="interfaceBoardLabel"
								label="Interface Board Type"
								inputProps={{
									id: "interface-board-dropdown",
									"data-testid": "interface-board-type-dropdown"
								}}
                onChange={(e) => 
                  onBoardTypeChange("interfaceBoardType", e.target.value)
                }
							>
								{Object.entries(INTERFACE_BOARD_TYPE).map((row) => {
									return (
										<MenuItem
											key={row[1]}
											value={row[1]}
											name={row[0]}
										>
											{row[0]}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
					)} />
			</Grid>
		</Grid>
	)
}

export default ProxReaderSettings