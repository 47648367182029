import React from "react";
import _ from "lodash";
import PayOnEntryOfferForm from "./OfferForm/"
import { useConfirmationDialog } from "../../../hooks/useConfirmationDialog";
import apiClient from "../../../auth/apiClient";
import PayOnEntryService from "../../../services/PayOnEntryService";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import usePoeState from "../../../state/slices/POE/usePoeState";
import { createOffer, updateOffer, removeOffer } from "../../../state/slices/POE/payOnEntry";
import {useDispatch} from "react-redux";

const payonentryService = new PayOnEntryService(apiClient);

const PayOnEntryComposer = ({ entityID, offerId, handleCancel, handleSave}) => {
  const { textConfirmation } = useConfirmationDialog();
  const enqueueSnackbar = useEnqueueSnackbar();
  const dispatch = useDispatch();
  const defaultPayOnEntry = {
    offerID: undefined,
    entityID : entityID,
    offerName: "",
    pricingType: "",
    fixFee: 0,
    rateID: "",
    duration: 0,
    active: true,
    useAdjustableFee: false
  };
  const { offers } = usePoeState();

  const formatOffers = (offers) => {    
      const selectedOffer = offers?.filter(offer => offer.offerID === offerId);
      if (selectedOffer.length) {
        return selectedOffer[0];
      } else {
        return defaultPayOnEntry;
      }
  };
  
  const addOffer = async data => {
    try {
      const result = await payonentryService.createPOEOffer(data);
      dispatch(createOffer(result.data));
    } catch (ex) {
      enqueueSnackbar("Failed to create offer", {
        variant: "error",
        tag: "FailedToCreateOfferInfo"
      });
    }
    handleSave();
  };

  const editOffer = async data => {
    try {
      const result = await payonentryService.updatePOEOffer(data);
      dispatch(updateOffer(result.data));
    } catch (ex) {
      enqueueSnackbar("Failed to update offer", {
        variant: "error",
        tag: "FailedToUpdateOfferInfo"
      });
    }
    handleSave();
  };

  const deleteOffer = async () => {
    const currentOffer = offers.filter(offer => offer.offerID === offerId)[0];
    const response = await textConfirmation({
      title: "Delete Confirmation",
      message: `If you're sure you want to delete it, please enter the offer name in the input box below.`,
      textConfirmation: currentOffer.offerName,
    });
    if (response===false) return;
    try {
      await payonentryService.deletePOEOffer(currentOffer.offerID);
      dispatch(removeOffer(currentOffer.offerID));
    } catch {
      enqueueSnackbar("Failed to delete offer", {
        variant: "error",
        tag: "FailedToDeleteOfferInfo"
      });
    }    
    handleSave();
  };

  return (
    <PayOnEntryOfferForm
    handleSave={addOffer}
    handleEdit={editOffer}
    handleDelete={deleteOffer}
    handleCancel={handleCancel}
    offer={formatOffers(offers)}/>
  )
};
export default PayOnEntryComposer;