import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import { useStyles } from "./styles";
import clsx from "clsx";
import { useForm, Controller } from "react-hook-form";
import moment from "moment";
import { DateTimePicker } from "@material-ui/pickers";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import { usePOEOfferSchedule } from "../../../hooks/usePOEOfferSchedule";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import PayOnEntryOfferRevealSchedule from '../PayOnEntryOfferRevealSchedule/PayOnEntryOfferRevealSchedule';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleXmark,
  faSave
} from '@fortawesome/free-solid-svg-icons';

const poeOfferRecurrenceSchema = Yup.object().shape({
  frequency: Yup.number().nullable(),
  start: Yup.date()
    .when('frequency', {
      is: (frequency) => frequency !== null,
      then: (schema) => schema.required('Required'),
      otherwise: (schema) => schema.nullable().notRequired()
    }),
  end: Yup.date()
    .when('frequency', {
      is: (frequency) => frequency !== null,
      then: (schema) => schema.required('Required').test(
        "is-greater",
        "End date cannot be before start date",
        function(value) {
          if (!value) return false;
          let { start } = this.parent;
          return (value > start);
        }
      ),
      otherwise: (schema) => schema.nullable().notRequired()
    }),
  until: Yup.date()
    .test(
      "after-end",
      "Until date cannot be before end date",
      function(value) {
        if (!value) return true;
        let { end } = this.parent;
        return (value > end);
      }
    )
    .nullable(),
  bydays: Yup.array()
    .when('frequency', {
      is: (frequency) => frequency === 2,
      then: (schema) => schema.min(1, 'At least one day must be selected'),
      otherwise: (schema) => schema.nullable()
    }),
  interval: Yup.number()
    .when('frequency', {
      is: (frequency) => frequency !== null,
      then: (schema) => schema.positive('Value must be a positive number').integer('Value must be a number').typeError('Value is required'),
      otherwise: (schema) => schema.nullable().notRequired()
    })
});

const PayOnEntryOfferSchedule = ({ isOpen, offer, handleCancel }) => {
  const [styleProps, setStyleProps] = useState({ display: 'none' });
  const classes = useStyles(styleProps);
  const enqueueSnackbar = useEnqueueSnackbar();
  const timeZone = useSelector(
    (state) =>
      state.entities.Context?.details?.timezone
        ? state.entities.Context.details.timezone
        : moment.tz.guess(),
    shallowEqual
  );
  const { 
    poeOfferSchedules, 
    poeOfferSchedulesLoading, 
    poeOfferSchedulesError, 
    daysOfWeek, 
    createPOEOfferSchedule,
    updatePOEOfferSchedule
  } = usePOEOfferSchedule(offer.offerID);
  const {
    control,
    getValues,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: { 
      id: null,
      frequency: null,
      start: null,
      end: null,
      until: null,
      bydays: [],
      interval: 1
    },
    resolver: yupResolver(poeOfferRecurrenceSchema),
    mode: "onChange"
  });
  const watchFrequency = watch("frequency");

  const onDayOfWeekChecked = (event) => {
    const newByDaysValue = getValues('bydays');

    if (event.target.checked) {
      newByDaysValue.push(event.target.id);
    } else {
      newByDaysValue.splice(newByDaysValue.indexOf(event.target.id), 1);
    }
    setValue("bydays", newByDaysValue);
  }

  const onSubmit = async (data) => {
    if (data.frequency !== null) {
      const offerSchedule = {
        OfferScheduleID: data.id,
        OfferID: offer.offerID,
        Start: data.start,
        End: data.end,
        Recurrence: true,
        RRuleFrequency: data.frequency === 2 ? "Weekly" : "Daily",
        RRuleUntil: data.until,
        RRuleByDays: data.frequency === 2 ? data.bydays.toString() : null,
        RRuleInterval: data.interval
      }

      try {
        if (offerSchedule.OfferScheduleID == undefined) {
          await createPOEOfferSchedule(offerSchedule);
        } else {
          await updatePOEOfferSchedule(offerSchedule);
        }
      } catch (error) {
        enqueueSnackbar(error.message, {
          variant: "error",
          tag: "OfferScheduleSave"
        });
      }
    }
  };

  useEffect(() => {
    if (poeOfferSchedules && poeOfferSchedules.recurrence) {
      const recurrence = poeOfferSchedules.recurrence

      reset({
        id: recurrence.id ? recurrence.id : null,
        frequency: recurrence.frequency,
        start: recurrence.start,
        end: recurrence.end,
        interval: recurrence.interval,
        bydays: recurrence.bydays,
        until: recurrence.until
      });
    }
  }, [poeOfferSchedules]);

  useEffect(() => {
    if (poeOfferSchedulesLoading) {
      setStyleProps({ display: 'block' });
    }
  }, [poeOfferSchedulesLoading])

  useEffect(() => {
    if (poeOfferSchedulesError) {
      enqueueSnackbar(poeOfferSchedulesError, {
        variant: "error",
        tag: "FailedToGetOfferSchedules"
      });
    }
  }, [poeOfferSchedulesError]);

  return (
    <Drawer
      anchor='right'
      open={isOpen}
      classes={{ 
        paper: classes.paper
      }}
      className={clsx("offer-schedule", "drawer")}
    >
      <Grid 
        container
        spacing={2}
        alignItems='center'
      >
        <Grid 
          item 
          xs={12} 
        >
          <Typography
            color='primary'
            component='h2'
            variant='h4'
          >
            Offer Schedule
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <Typography
            component='span'
            variant="body1"
          >
            The reveal schedule for <strong>{offer.offerName}</strong> can be recurring, scheduled, or a combination of both.
          </Typography>
        </Grid>
        <Grid 
          item
          xs={12}
        >
          <Typography
            component='h4'
            variant='h5'
          >
            Recurring
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <Typography 
            component='span'
            variant="body1"
          >
            A recurring pattern will go online according to the time and day selected.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <Controller
            name="frequency"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                className={classes.recurrenceTypeSelector}
                data-id="frequency"
                id="frequency"
                name="frequency"
                label='Frequency'
                select
                disabled={poeOfferSchedulesLoading}
                variant="outlined"
                value={field.value}
                error={!!errors.frequency}
                helperText={errors.frequency && errors.frequency.message}
                onChange={(e) => {
                  setValue("frequency", e.target.value);
                }}
                inputProps={{
                  "data-testid": "frequency-select",
                }}
                size='small'
              >
                <MenuItem value={3}>Daily</MenuItem>
                <MenuItem value={2}>Weekly</MenuItem>
              </TextField>
            )}
          />
        </Grid>
        <Grid 
          item
          sm={6}
          xs={12}
        >
          <Controller
            control={control}
            name="start"
            render={({ field }) => (
              <DateTimePicker
                {...field}
                disablePast={true}
                className={classes.dateField}
                label="First Occurrence Start"
                value={
                  field.value
                    ? moment.utc(field.value).tz(timeZone)
                    : null
                }
                disabled={poeOfferSchedulesLoading}
                format="MM/DD/YY hh:mm a"
                error={!!errors.start}
                helperText={errors.start && errors.start.message}
                id="start"
                onChange={field.onChange}
                inputVariant='outlined'
                size='small'
                InputProps={{
                  'data-testid': 'poe-offer-recurrence-startdate'
                }}
                fullWidth
              />    
            )}
          />  
        </Grid>
        <Grid 
          item
          sm={6}
          xs={12}
        >        
          <Controller
            control={control}
            name="end"
            render={({ field }) => (
              <DateTimePicker
                {...field}
                className={classes.dateField}
                label="First Occurrence End"
                disabled={poeOfferSchedulesLoading}
                disablePast={true}
                value={
                  field.value
                    ? moment.utc(field.value).tz(timeZone)
                    : null
                }
                error={!!errors.end}
                helperText={errors.end && errors.end.message}
                format="MM/DD/YY hh:mm a"
                id="end"
                onChange={field.onChange}
                inputVariant='outlined'
                size='small'
                InputProps={{
                  'data-testid': 'poe-offer-recurrence-enddate'
                }}
                fullWidth
              />    
            )}
          />  
        </Grid>
        <Grid
          container
          direction='row'
          item
          justifyContent='flex-start'
          alignItems='center'
          spacing={2}
        >
          <Grid
            item
            xs={3}
          >
            <Typography
              component='span'
              variant='body1'
            >
              Repeat every
            </Typography>
          </Grid>
          <Grid 
            item 
            xs={3}
          >
            <Controller
              control={control}
              name="interval"
              render={({ field }) => (
                <TextField
                  {...field}
                  id="interval"
                  className={classes.textField}
                  onChange={field.onChange}
                  disabled={poeOfferSchedulesLoading}
                  value={field.value ?? 1}
                  error={!!errors.interval}
                  InputProps={{
                    inputProps:  {
                      type: "number",
                      min: 1,
                      max: watchFrequency === 2 ? 52 : 366,
                      step: "1",
                      pattern: "[0-9]*",
                      inputMode: "numeric",
                    }
                  }}
                  variant='outlined'
                  size='small'
                  fullWidth
                />    
              )} 
            />    
          </Grid>
          <Grid
            item
            xs={6}
          >
            <Typography
              component='span'
              variant='body1'
            >
              {watchFrequency === 2 ? "week(s) on:" : "day(s)"}
            </Typography>

          </Grid>
          {errors.interval && errors.interval.message &&
            <>
              <Grid
                item
                xs={3}
              ></Grid>
              <Grid
                item
                xs={9}
              >
                <Typography
                  color='secondary'
                  variant='caption'
                >
                  {errors.interval.message}
                </Typography>
              </Grid>
            </>
          }
          
        </Grid>
        {watchFrequency === 2 && 
          <>
            <Grid
              container
              item
              columns={{ xs: 12, sm: 6, md: 4}}
            >
              <>
                {daysOfWeek.map((day) => (
                  <Grid 
                    item
                    xs={12}
                    sm={6}
                    md={4} 
                    key={day.name} 
                  >
                    <Controller
                      name="bydays"
                      control={control}
                      key={day.name}
                      render={({ field }) => (
                        <FormControlLabel
                          label={
                            <label className={clsx("dayofweek", classes.checkboxLabel)}>
                              {day.name}
                            </label>
                          }
                          control={
                            <Checkbox
                              {...field}
                              id={day.name}
                              key={day.name}
                              color="primary"
                              checked={getValues('bydays').includes(day.name)}
                              data-checked={getValues('bydays').includes(day.name)}
                              disabled={poeOfferSchedulesLoading}
                              onChange={onDayOfWeekChecked}
                              data-testid={`poe-offer-recurrence-${day.name.toLowerCase()}-checkbox`}
                            />
                          }
                        />
                      )}
                    />
                  </Grid>
                ))}
              </>
            </Grid>
            {errors.bydays  && errors.bydays.message &&
              <Grid
                item
                xs={12}
              >
                <Typography
                  color='secondary'
                  variant='caption'
                >
                  {errors.bydays.message}
                </Typography>
              </Grid>
            }
            
          </>
        }
        <Grid 
          item
          xs={12}
        >
          <Controller
            control={control}
            name="until"
            render={({ field }) => (
              <DateTimePicker
                {...field}
                className={classes.repeatUntil}
                label="Repeat Until"
                disablePast={true}
                minDate={new Date()}
                minDateMessage="Date must be in the future"
                value={
                  field.value
                    ? moment.utc(field.value).tz(timeZone)
                    : null
                }
                error={!!errors.until}
                helperText={errors.until && errors.until.message}
                format="MM/DD/YY hh:mm a"
                id="until"
                onChange={field.onChange}
                inputVariant='outlined'
                size='small'
                InputProps={{
                  'data-testid': 'poe-offer-recurrence-untildate'
                }}
                fullWidth
              />    
            )}
          />    
        </Grid>
      </Grid>
      <Grid container className={classes.scheduledContainer}>
        <PayOnEntryOfferRevealSchedule
          offerId={offer.offerID}
        />
      </Grid>
      <Grid 
        className={clsx([classes.footer])}
        container 
        direction='row'
        item
        justify='flex-end'
        spacing={2}
      >
        <Grid item>
          <Button
            type='submit'
            color="primary"
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            className={clsx("saveBtn")}
            startIcon={<FontAwesomeIcon icon={faSave} />}
            disabled={poeOfferSchedulesLoading}
          >
            {poeOfferSchedulesLoading &&
              <div className={classes.overlay}>
                <div className={classes.overlayContent}>
                  <CircularProgress
                    color='inherit'
                    size={20}
                  />
                </div>
              </div>
            }
            Save
          </Button>
        </Grid>
        <Grid item>
          <Button
            className={clsx("cancelBtn")}
            name="cancel"
            variant="contained"
            onClick={handleCancel}
            startIcon={<FontAwesomeIcon icon={faCircleXmark} />}
            disabled={poeOfferSchedulesLoading}
          >
            Close
          </Button>
        </Grid>
      </Grid>
    </Drawer>
  )
}

export default PayOnEntryOfferSchedule;