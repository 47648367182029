import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import useAuthContext from "../../hooks/useAuthContext";
import { AuthStatus } from "../../reducers/auth/authReducer";
import useHasPermissions from "../../hooks/useHasPermissions";

const ProtectedRoute = ({
  component: Comp,
  path,
  permissions,
  hasAny,
  ...rest
}) => {
  const { hasPermissions } = useHasPermissions();
  const { authReducer } = useAuthContext();
  const [authState] = authReducer;

  const isAuthorized = () =>
    permissions === undefined ||
    permissions.length === 0 ||
    hasPermissions(permissions, hasAny ?? false);

  return (
    <Route
      path={path}
      {...rest}
      render={props => {
        if (authState.authStatus === AuthStatus.UNAUTHENTICATED) {
          return <Redirect to="/login" />;
        } else if (isAuthorized()) {
          return <Comp {...props} />;
        } else {
          return <Redirect to={`/home`} />;
        }
      }}
    />
  );
};

ProtectedRoute.propTypes = {
  hasAny: PropTypes.bool
};

export default ProtectedRoute;
