import React, { useState, useEffect } from "react";
import { FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Switch, TextField, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useStyles } from "./styles.js";
import { useEnqueueSnackbar } from "../../../../../hooks/useEnqueueSnackbar";
import apiClient from "../../../../../auth/apiClient";
import GPIOService from "../../../../../services/GPIOService";

import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { GATE_TYPE } from "../../../../../constants";
import _ from "lodash";

export const gateSettingsSchema = Yup.object().shape({
	gateType: Yup.number().required(),
	isVend: Yup.bool().when('gateType', {
		is: 2,
		then: (schema) => schema.required()
	}),
	armingLoop: Yup.object().when('gateType', {
		is: 2,
		then: (schema) => schema.required().shape({
			ioMappingID: Yup.number().required()
		})
	}),
	closingLoop: Yup.object().when('gateType', {
		is: 2,
		then: (schema) => schema.required().shape({
			ioMappingID: Yup.number().required()
		})
	}),
	gateStatus: Yup.object().when('gateType', {
		is: 2,
		then: (schema) => schema.required().shape({
			ioMappingID: Yup.number().required()
		})
	}),
	gate: Yup.object().when(['gateType', 'isVend'], {
		is: (gateType, isVend) => gateType === 2 && !isVend,
		then: (schema) => schema.required().shape({
			ioMappingID: Yup.number().required(),
			openHigh: Yup.bool().required()
		})
	}),
	gateClose: Yup.object().when(['gateType', 'isVend'], {
		is: (gateType, isVend) => gateType === 2 && isVend,
		then: (schema) => schema.required().shape({
			ioMappingID: Yup.number().required(),
			duration: Yup.number().required().min(1)
		})
	}),
	gateVend: Yup.object().when(['gateType', 'isVend'], {
		is: (gateType, isVend) => gateType === 2 && isVend,
		then: (schema) => schema.required().shape({
			ioMappingID: Yup.number().required(),
			duration: Yup.number().required().min(1)
		})
	}),

});

const gpioService = new GPIOService(apiClient);
const GateSettings = ({ peripheral, updatePeripheralSettings }) => {
	const classes = useStyles();

	const gateOptions = [
		{
			name: "1200",
			id: 1
		},
		{
			name: "GPIO",
			id: 2
		}];


	const settingsClone = _.cloneDeep(peripheral?.settings);

	const { control, setValue, watch, reset, getValues } = useForm({
		mode: 'onChange',
		reValidateMode: 'onChange',
		resolver: yupResolver(gateSettingsSchema),
		defaultValues: {
			gateType: settingsClone?.gateType ?? 1,
			isVend: settingsClone?.isVend ?? undefined,
			armingLoop: settingsClone?.armingLoop ?? undefined,
			closingLoop: settingsClone?.closingLoop ?? undefined,
			gateStatus: settingsClone?.gateStatus ?? undefined,
			gate: settingsClone?.gate ?? undefined,
			gateClose: settingsClone?.gateClose ?? undefined,
			gateVend: settingsClone?.gateVend ?? undefined,
		},
	});

	const watchFields = watch();

	const enqueueSnackbar = useEnqueueSnackbar();

	const defaultIOMapping = {
		friendlyName: "None",
		ioMappingID: 0
	};
	const defaultIOMappingsArray = [defaultIOMapping];
	const [GPIOMappings, setGPIOMappings] = useState(defaultIOMappingsArray);

	const GetGPIOMappingInfo = async () => {
		try {
			var result = await gpioService.getIOMappings(peripheral.entityID);
			result.data.unshift(defaultIOMapping);
			setGPIOMappings(result.data);
		} catch (error) {
			setGPIOMappings(defaultIOMappingsArray);
			enqueueSnackbar("Failed to get GPIOMappings", {
				variant: "error",
				tag: "getGPIOError"
			});
		}
	}

	useEffect(() => {
		GetGPIOMappingInfo();
	}, [peripheral.entityID]);

	useEffect(() => {
		const subscription = watch(() => updatePeripheralSettings(getValues(), peripheral.peripheralID));

		return () => subscription.unsubscribe();
	}, [watch]);

	const onGateOptionChanged = (value) => {
		if (value === 2) {
			setValue("gateType", 2);
			setValue("isVend", false);
			setValue("armingLoop", {
				"ioMappingID": 0,
			});
			setValue("closingLoop", {
				"ioMappingID": 0,
			});
			setValue("gateStatus", {
				"ioMappingID": 0,
			});
			setValue("gate", {
				"ioMappingID": 0,
				"openHigh": true
			});
		} else {
			reset({
				gateType: 1,
				isVend: undefined,
				armingLoop: undefined,
				closingLoop: undefined,
				gateStatus: undefined,
				gate: undefined,
				gateClose: undefined,
				gateVend: undefined,
			}, { keepDefaultValues: true });
		}
	}

	const onIsVendToggled = (value) => {
		setValue("isVend", value);

		if (value) {
			setValue("gateClose", {
				"ioMappingID": 0,
				"duration": 1000
			})
			setValue("gateVend", {
				"ioMappingID": 0,
				"duration": 1000
			})
			setValue("gate", undefined )
		} else {
			setValue("gateClose", undefined )
			setValue("gateVend", undefined )
			setValue("gate", {
				"ioMappingID": 0,
				"openHigh": true
			})
		}
	}

	const shouldShowMapping = (value, field) => {
		if (value.ioMappingID === 0 || value.ioMappingID === getValues(field).ioMappingID) {
			return true
		}
		let selectedIds = Object.entries(getValues()).filter(e => e[0] !== field && e[1]).map(e => e[1].ioMappingID);
		return !selectedIds.includes(value.ioMappingID)
	}

	return (
		<Grid container spacing={4}>
			<Grid item xs={12}>
				<Controller
					name="gateType"
					control={control}
					render={({ field }) => (
						<FormControl fullWidth variant="outlined">
							<InputLabel id="gateLabel" className={clsx("dropdown-label")}>Gate Type</InputLabel>
							<Select
								{...field}
								displayEmpty
								data-id="gate-type-dropdown"
								className={clsx("gate-dropdown")}
								variant="outlined"
								fullWidth
								labelId="gateLabel"
								label="Gate Type"
								inputProps={{
									id: "gate-dropdown",
									"data-testid": "gate-type-dropdown"
								}}
								onChange={(e) => {
									onGateOptionChanged(e.target.value);
								}}
							>
								{Object.entries(GATE_TYPE).map((row) => {
									return (
										<MenuItem
											key={row[1]}
											value={row[1]}
											className={clsx(`${row[0]}-menu-item`)}
											name={row[0]}
										>
											{row[0]}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
					)} />
			</Grid>
			{watchFields.gateType === GATE_TYPE.GPIO &&
				<>
					<Grid component="label" container alignItems="center" item xs={12}>
						<Controller
							name="isVend"
							control={control}
							render={({ field }) => (
								<>
									<Grid item>Gate Open/Close</Grid>
									<Grid item>
										<Switch
											{...field}
											checked={field.value}
											data-checked={field.value}
											onChange={(e) => {
												onIsVendToggled(e.target.checked)
											}}
											data-id="is-vend-switch"
											inputProps={{
												"data-testid": "is-vend-switch"
											}}
										/>
									</Grid>
									<Grid item>Gate Vend</Grid>
								</>
							)} />
					</Grid>
					<Grid item xs={12}>
						<Controller
							name="armingLoop.ioMappingID"
							control={control}
							render={({ field }) => (
								<GPIOMapping
									{...field}
									labelId="armingLoopLabel"
									labelClass={"dropdown-label"}
									label="Arming Loop"
									dropdownDataId="arming-loop-mapping-dropdown"
									dropdownClass="arming-loop-dropdown"
									GPIOMappings={GPIOMappings.filter(m =>
										shouldShowMapping(m, "armingLoop")
									)}
								/>
							)} />
					</Grid>
					<Grid item xs={12}>
						<Controller
							name="closingLoop.ioMappingID"
							control={control}
							render={({ field }) => (
								<GPIOMapping
									{...field}
									labelId="closingLoopLabel"
									labelClass={"dropdown-label"}
									label="Closing Loop"
									dropdownDataId="closing-loop-mapping-dropdown"
									dropdownClass="closing-loop-dropdown"
									GPIOMappings={GPIOMappings.filter(m => shouldShowMapping(m, "closingLoop"))}
								/>
							)} />
					</Grid>
					<Grid item xs={12}>
						<Controller
							name="gateStatus.ioMappingID"
							control={control}
							render={({ field }) => (
								<GPIOMapping
									{...field}
									labelId="gateStatusLabel"
									labelClass={"dropdown-label"}
									label="Gate Status"
									dropdownDataId="gate-status-mapping-dropdown"
									dropdownClass="gate-status-dropdown"
									GPIOMappings={GPIOMappings.filter(m => shouldShowMapping(m, "gateStatus"))}
								/>
							)} />
					</Grid>
					{watchFields.isVend &&
						<>
							<Grid container item xs={12}>
								<Grid item xs={12}>
									<Controller
										name="gateVend.ioMappingID"
										control={control}
										render={({ field }) => (
											<GPIOMapping
												{...field}
												labelId="gateVendLabel"
												labelClass={"dropdown-label"}
												label="Gate Vend"
												dropdownDataId="gate-vend-mapping-dropdown"
												dropdownClass="gate-vend-dropdown"
												GPIOMappings={GPIOMappings.filter(m => shouldShowMapping(m, "gateVend"))}
											/>
										)} />
								</Grid>
								<Grid item xs={12} md={4}>
									<Controller
										name="gateVend.duration"
										control={control}
										render={({ field, fieldState: { invalid } }) => (
											<TextField
												{...field}
												variant="outlined"
												id="gate-vend-duration"
												label="Duration"
												type="number"
												className={classes.spacedInput}
												onChange={(e) => {
													field.onChange(e.target.value);
												}}
												helperText={invalid ? "Minimum value is 1 millisecond" : "in milliseconds"}
												fullWidth
												InputProps={{
													inputProps: {
														"data-testid": `gate-vend-duration`,
													}
												}}
												error={invalid}
											/>
										)} />
								</Grid>
							</Grid>
							<Grid item container xs={12}>
								<Grid item xs={12}>
									<Controller
										name="gateClose.ioMappingID"
										control={control}
										render={({ field }) => (
											<GPIOMapping
												{...field}
												labelId="gateCloseLabel"
												labelClass={"dropdown-label"}
												label="Gate Close"
												dropdownDataId="gate-close-mapping-dropdown"
												dropdownClass="gate-close-dropdown"
												GPIOMappings={GPIOMappings.filter(m => shouldShowMapping(m, "gateClose"))}
											/>
										)} />
								</Grid>
								<Grid item xs={12} md={4}>
									<Controller
										name="gateClose.duration"
										control={control}
										render={({ field, fieldState: { invalid } }) => (
											<TextField
												{...field}
												variant="outlined"
												id="gate-close-duration"
												label="Duration"
												type="number"
												className={classes.spacedInput}
												onChange={(e) => {
													field.onChange(e.target.value);
												}}
												helperText={invalid ? "Minimum value is 1 millisecond" : "in milliseconds"}
												fullWidth
												InputProps={{
													inputProps: {
														"data-testid": `gate-close-duration`,
													}
												}}
												error={invalid}
											/>
										)} />
								</Grid>
							</Grid>
						</>
					}
					{!watchFields.isVend &&
						<Grid item container xs={12}>
							<Grid item xs={12}>
								<Controller
									name="gate.ioMappingID"
									control={control}
									render={({ field }) => (
										<GPIOMapping
											{...field}
											labelId="gateLabel"
											labelClass={"dropdown-label"}
											label="Gate Open/Close"
											dropdownDataId="gate-mapping-dropdown"
											dropdownClass="gate-dropdown"
											GPIOMappings={GPIOMappings.filter(m => shouldShowMapping(m, "gate"))}
										/>
									)} />
							</Grid>
							<Grid item xs={12} md={6}>
								<Controller
									name="gate.openHigh"
									control={control}
									render={({ field }) => (
										<FormControl fullWidth variant="outlined" className={classes.spacedInput}>
											<InputLabel id="highLowLabel" className={clsx("high-low-label")}>Open gate pin state</InputLabel>
											<Select
												{...field}
												data-id="highLowDropdown"
												className={clsx("high-low-dropdown")}
												variant="outlined"
												fullWidth
												labelId="highLowLabel"
												label="Open gate pin state"
												inputProps={{
													id: "high-low-dropdown",
													"data-testid": "highLowDropdown"
												}}
												onChange={(e) => {
													field.onChange(e.target.value);
												}}
											>
												{["High", "Low"].map((row) => {
													return (
														<MenuItem
															key={row}
															value={row === "High"}
															className={clsx(`${row}-menu-item`)}
															name={row}
														>
															{row}
														</MenuItem>
													);
												})}
											</Select>
										</FormControl>
									)} />
							</Grid>
						</Grid>
					}
				</>
			}
		</Grid>
	)
}

const GPIOMapping = ({ labelId, label, labelClass, dropdownDataId, dropdownClass, GPIOMappings, onChange, value, name }) => {
	return (
		<FormControl fullWidth variant="outlined">
			<InputLabel id={labelId} className={clsx(labelClass)}>{label}</InputLabel>
			<Select
				displayEmpty
				data-id={dropdownDataId}
				className={clsx(dropdownClass)}
				variant="outlined"
				fullWidth
				labelId={labelId}
				label={label}
				name={name}
				inputProps={{
					id: dropdownClass,
					"data-testid": dropdownDataId
				}}
				onChange={(e) => onChange(e.target.value)}
				value={(value ?? 0)}
			>
				{GPIOMappings?.map((row) => {
					return (
						<MenuItem
							key={row.ioMappingID}
							value={row.ioMappingID}
							className={clsx(`${row.friendlyName}-menu-item`)}
							name={row.friendlyName}
						>
							{row.friendlyName}
						</MenuItem>
					);
				})}
			</Select>
		</FormControl>
	)
}

export default GateSettings