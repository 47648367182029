
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  header: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.light,
  },
  subheader: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '20px',
    color: theme.palette.primary.light,
  },
  root: {
    denomRow: {
      paddingTop: '4px',
      paddingBottom: '4px',
    },
  },
  table: {
    fontSize: theme.typography.h6,
  },
  tablecontainer: {
    alignItems: "right",
    marginRight: theme.spacing(1),
  },
  bnrAlert: {
    width: "100%",
  },
  submitBtn: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    float: "left",
  },
  tableRow: {
  },
  tableCell: {
    maxWidth: "160px",
    padding: "14px",
  },
  headerCell: {
    color: theme.palette.primary.light,
    maxWidth: "160px",
    padding: "14px",
    fontSize: '16px',
  },
  inputField: {
  }
}));