import React, { useState, useEffect } from "react";
import { useStyles } from "./styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import useHubContext from "../../../../../../hooks/useHubContext";
import {
  PORTAL_TRIGGER,
  MONEY_INVENTORY_REQUEST_TOPIC,
  MONEY_INVENTORY_TOPIC
} from "../../../../../../constants";
import clsx from "clsx";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import * as Yup from "yup";
import { useFormik } from "formik";
import CheckIcon from "@material-ui/icons/Check";
import {Button, LinearProgress, Tooltip, Typography} from "@material-ui/core";
import { useEnqueueSnackbar } from "../../../../../../hooks/useEnqueueSnackbar";

export const BNRConfiguration = ({ entityid, deviceService, override }) => {
  // Declarations
  const classes = useStyles();
  const { portalHub } = useHubContext();
  const [recyclerConfigurations, setRecyclerConfigurations] = useState();
  const [isFetchingConfig, setIsFetchingConfig] = useState(override !== false);
  const enqueueSnackbar = useEnqueueSnackbar();

  //Functions
  const createHeaderRow = () => (
    <TableRow className={classes.header}>
      <TableCell className={classes.headerCell} align="left" colSpan={2} />
      <TableCell className={classes.headerCell} align="left" colSpan={2}>
        Denomination
      </TableCell>
      <TableCell className={classes.headerCell} align="left" colSpan={2}>
        Full
      </TableCell>
      <TableCell className={classes.headerCell} align="left" colSpan={2}>
        High
      </TableCell>
      <TableCell className={classes.headerCell} align="left" colSpan={2}>
        Low
      </TableCell>
    </TableRow>
  );

  const handleSubmitConfiguration = async (values) => {
    setIsFetchingConfig(true);
    try {
      const config = {
        EntityID: entityid,
        RecyclerConfigurations: values.recyclerConfigurations,
        Action: "ConfigureCashUnit"
      };
      const result = await deviceService.configureBNR(config);
      if (result.status === 200) {
        enqueueSnackbar("Successfully sent configuration message to the device", {
          variant: "success", tag: "configureCashUnitMessageSuccess"
        });
      }
    } catch (err) {
      enqueueSnackbar("Failed to send configuration message to cash unit", {
        variant: "error", tag: "configureCashUnitMessageError"
      });
    }
  };

  // UseEffects
  useEffect(() => {
    portalHub.subscribe(
      `${entityid}_${MONEY_INVENTORY_TOPIC}`,
      (message) => {
        const data = JSON.parse(message);
        if (data.Action === "CashConfigResponse") {
          const messageData = JSON.parse(data.Message);
          resetForm({});
          setRecyclerConfigurations(messageData.recyclerConfigurations);
          setIsFetchingConfig(false);
        }
      }
    );

    setTimeout(() => {
      portalHub.invoke(PORTAL_TRIGGER, {
        entityID: entityid,
        topic: MONEY_INVENTORY_REQUEST_TOPIC,
        method: "",
        action: "CashUnitConfigRequest", // Gives cash unit config instead of bnr totals
        message: "",
      });
    }, 2000);

    return () => {
      portalHub.unsubscribe(`${entityid}_${MONEY_INVENTORY_TOPIC}`);
    };
  }, [portalHub.connection, portalHub.isOpen]);

  const {
    isValid,
    values,
    errors,
    submitForm,
    handleChange,
    dirty,
    handleBlur,
    resetForm
  } = useFormik({
    initialValues: {
      recyclerConfigurations: recyclerConfigurations
    },
    validationSchema: BNRConfigSchema,
    enableReinitialize: true,
    onSubmit: handleSubmitConfiguration,
  });

  // DOM
  return (
    <div className={classes.tablecontainer}>
      <Typography className={classes.subheader}>Recycler Configuration</Typography>
      {isFetchingConfig && <LinearProgress />}
      {!isFetchingConfig && (
      <>
        <Table
          className={clsx(["cashConfigurationTable", classes.table])}
          aria-label="spanning table"
        >
          <TableBody>
            {createHeaderRow()}
            <ConfigurationTableInput
              recyclerConfigs={values}
              errors={errors}
              handleChange={handleChange}
              handleBlur={handleBlur}
              classes={classes}
              enqueueSnackbar={enqueueSnackbar}
              isConfiguring={isFetchingConfig}
            />
          </TableBody>
        </Table>
        <Button
          className={clsx(["submitBtn", classes.submitBtn])}
          onClick={submitForm}
          variant="contained"
          color="primary"
          disabled={!isValid || !dirty}
          startIcon={<CheckIcon />}
        >
          Configure
        </Button>
      </>
      )}
    </div>
  )
};

export default BNRConfiguration;

// Need to manage array of configuration objects in formik state
export const ConfigurationTableInput = ({
  recyclerConfigs,
  errors,
  handleChange,
  handleBlur,
  classes,
  enqueueSnackbar,
  isConfiguring
}) => (
  <>
    {recyclerConfigs?.recyclerConfigurations?.map((config, index) => (
      <Tooltip
        className={clsx("group-tooltip")}
        data-id={index}
        arrow
        title={config.BillCount > 0 ? "Cannot change configuration if recycler is not empty" : ""}
        key={`helper-text-${index}`}
      >
        <TableRow
          key={`${config.RecyclerName}-${config.Denomination}`}
          className={clsx(["recyclerConfigRow", classes.tableRow])}
          data-id={index}
        >
          <TableCell
            className={clsx(["recyclerRowName", classes.tableCell])}
            align="left"
            colSpan={2}
            data-id={index}
          >
            {config.RecyclerName}
          </TableCell>
          <TableCell
            className={clsx(["recyclerRowDenom", classes.tableCell])}
            align="center"
            colSpan={2}
            data-id={index}
          >
            <Select
              native
              name={`recyclerConfigurations[${index}].Denomination`}
              defaultValue={config.Denomination}
              onChange={(e) => {
                handleChange(e);
                enqueueSnackbar("Exercise caution configuring recyclers", { variant: "warning" })
              }}
              onBlur={handleBlur}
              className={"recyclerRowDenomSelect"}
              data-id={index}
              disabled={config.BillCount > 0 || isConfiguring === true}
            >
              {denominationArray.map((denom) => (
                <option key={`${index}-${denom}`} value={denom}>{`$${denom}`}</option>
              ))}
            </Select>
          </TableCell>
          <TableCell
            className={clsx(["recyclerRowFull", classes.tableCell])}
            align="left"
            colSpan={2}
            data-id={index}
          >
            <TextField
              type="number"
              inputProps={{ min: 0, step: 1 }}
              className={clsx(["recyclerRowFullInput", classes.inputField])}
              data-id={index}
              name={`recyclerConfigurations[${index}].FullThreshold`}
              value={config.FullThreshold}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors?.recyclerConfigurations && errors?.recyclerConfigurations[index]?.FullThreshold}
              helperText={errors?.recyclerConfigurations &&
              errors?.recyclerConfigurations[index]?.FullThreshold?.replace(`recyclerConfigurations[${index}].FullThreshold`,
                `${config.RecyclerName}'s full threshold`)
              }
              disabled={config.BillCount > 0 || isConfiguring === true}
            />
          </TableCell>
          <TableCell
            className={clsx(["recyclerRowHigh", classes.tableCell])}
            align="left"
            colSpan={2}
            data-id={index}
          >
            <TextField
              type="number"
              inputProps={{ min: 0, step: 1 }}
              className={clsx(["recyclerRowHighInput", classes.inputField])}
              data-id={index}
              name={`recyclerConfigurations[${index}].HighThreshold`}
              value={config.HighThreshold}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors?.recyclerConfigurations && errors?.recyclerConfigurations[index]?.HighThreshold}
              helperText={errors?.recyclerConfigurations &&
              errors?.recyclerConfigurations[index]?.HighThreshold?.replace(`recyclerConfigurations[${index}].HighThreshold`,
                `${config.RecyclerName}'s high threshold`)
              }
              disabled={config.BillCount > 0 || isConfiguring === true}
            />
          </TableCell>
          <TableCell
            className={clsx(["recyclerRowLow", classes.tableCell])}
            align="left"
            colSpan={2}
            data-id={index}
          >
            <TextField
              type="number"
              inputProps={{ min: 0, step: 1 }}
              className={clsx("recyclerRowLowInput", classes.inputField)}
              data-id={index}
              name={`recyclerConfigurations[${index}].LowThreshold`}
              value={config.LowThreshold}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors?.recyclerConfigurations && errors?.recyclerConfigurations[index]?.LowThreshold}
              helperText={errors?.recyclerConfigurations &&
              errors?.recyclerConfigurations[index]?.LowThreshold?.replace(`recyclerConfigurations[${index}].LowThreshold`,
                `${config.RecyclerName}'s low threshold`)
              }
              disabled={config.BillCount > 0 || isConfiguring === true}
            />
          </TableCell>
        </TableRow>
      </Tooltip>
    ))}
  </>
);

// Constants
export const denominationArray = [ 1, 2, 5, 10, 20, 50, 100 ];

const conditionalRecValidator = Yup.number().when("RecyclerName", {
  is: value => value === "RE4" || value === "RE6",
  then: Yup.number().positive().min(0).max(60).required('High Threshold Required'),
  otherwise: Yup.number().positive().min(0).max(30).required('High Threshold Required')
});

export const BNRConfigSchema = Yup.object().shape({
  recyclerConfigurations: Yup.array()
    .of(
      Yup.object().shape({
        RecyclerName: Yup.string().required(),
        Denomination: Yup.number().oneOf(denominationArray).required('Denomination Required'),
        LowThreshold: conditionalRecValidator,
        HighThreshold: conditionalRecValidator,
        FullThreshold: conditionalRecValidator,
      })
    )
});
