import React, { useState, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Grid,
  Button,
  Divider,
  Typography,
  FormControl,
  MenuItem,
  TextField,
  InputAdornment,
  Tooltip,
  FormControlLabel,
  Switch
} from "@material-ui/core";
import Title from "../../../Title";
import _ from "lodash";
import { useStyles } from "./styles";
import clsx from "clsx";
import { POE_PRICING_TYPE } from "../../../../constants/index";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import useHasPermissions from "../../../../hooks/useHasPermissions";
import DurationEntry from "../../../Rate/Inputs/DurationEntry";
import apiClient from "../../../../auth/apiClient";
import RateService from "../../../../services/RateService";
import { useEnqueueSnackbar } from "../../../../hooks/useEnqueueSnackbar";
import POEBannerImage from "../../POEBannerImage";
import { useFlags } from "launchdarkly-react-client-sdk";
import PayOnEntryOfferSchedule from "../../PayOnEntryOfferSchedule";

const rateService = new RateService(apiClient);

export const payonentryOfferSchema = Yup.object().shape({
  offerName: Yup.string()
    .required("Required")
    .max(25, "Maximum of 25 characters"),
  pricingType: Yup.string().required("Required"),
  fixFee: Yup.number()
    .typeError("Must be a positive number")
    .positive("Must be a positive number")
    .min(0, "Must be a positive number")
    .required("Required"),
  duration: Yup.number()
    .typeError("Must be a positive number")
    .positive("Must be a positive number")
    .min(0, "Must be a positive number")
    .required("Required"),
  rateID: Yup.string().required("Required"),
});

const PayOnEntryOfferForm = ({
  handleSave,
  handleEdit,
  handleDelete,
  handleCancel,
  offer,
}) => {
  const classes = useStyles();
  const enqueueSnackbar = useEnqueueSnackbar();
  const { hasPermissions } = useHasPermissions();
  const DeletePayOnEntryPermission = hasPermissions(["payonentry.delete"]);
  const { payOnEntryImages, payOnEntryRevealSchedule } = useFlags();
  const [ isOfferScheduleDrawerOpen, setIsOfferScheduleDrawerOpen] = useState();
  const scopeAwareFacilityID = useSelector((state) => state.entityScope?.facilityGroupId || offer?.entityID);

  const {
    setValue,
    handleSubmit,
    watch,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: { ...offer },
    resolver: yupResolver(payonentryOfferSchema),
    shouldUnregister: false,
  });

  const [displayFee, setDisplayFee] = useState(
    offer.pricingType === "Flatfee" ? true : false
  );
  const [availableRates, setAvailableRates] = useState([]);
  const watchedPricingType = watch("pricingType");

  useEffect(() => {
    if (scopeAwareFacilityID !== null) {
      getAvailableRates();
    }
  }, [scopeAwareFacilityID]);

  useEffect(() => {
    setDisplayFee(watchedPricingType === "Flatfee" ? true : false);
    if (watchedPricingType !== "Flatfee") {
      setValue("fixFee", 0);
    }
  }, [watchedPricingType]);

  const getAvailableRates = useCallback(async () => {
    rateService
      .getAvailableRates(scopeAwareFacilityID)
      .then((result) => {
        if (result !== null) {
          setAvailableRates(result.data);
        } else {
          enqueueSnackbar("No rates exist for this entity", {
            variant: "error",
            tag: "NoRatesExistForThisEntity",
          });
        }
      })
      .catch((err) => {
        enqueueSnackbar("Unable to retrieve rates for this entity", {
          variant: "error",
          tag: "FailedToFindRatesForEntity",
        });
      });
  }, [scopeAwareFacilityID]);

  const onSubmit = async (data) => {
    if (offer.offerID === undefined) {
      await handleSave(data);
    } else {
      await handleEdit(data);
    }
  };

  const formatOfferName = (e) => {
    setValue(e.target.name, e.target.value.trim());
  };

  const handleNewParam = (value) => {
    setValue("encodedImage", value.encodedImage);
    setValue("imageExtension", value.imageExtension);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={8} md={8}>
          <Typography className={clsx([classes.offerTitle])}>
            <Title>
              {offer.offerID === undefined ? "Add Offer" : "Edit Offer"}
            </Title>
          </Typography>
        </Grid>
        {offer.offerID && DeletePayOnEntryPermission === true && (
          <Grid item xs={4} md={4} className={clsx([classes.drawerContainer])}>
            <Button
              className={clsx("deleteBtn", classes.btnControl)}
              name="delete"
              variant="contained"
              color="secondary"
              onClick={handleDelete}
              disabled={isSubmitting}
            >
              Delete
            </Button>
          </Grid>
        )}
      </Grid>
      <Divider></Divider>
      <Grid container className={clsx([classes.drawerContainer])}>
        <Grid item xs={12} sm={12} className={clsx([classes.drawerItems])}>
          <Controller
            name="offerName"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth FormLabel="Offer Name">
                <TextField
                  {...field}
                  onBlur={formatOfferName}
                  id="offerName"
                  className={clsx("offerName", classes.flexInput)}
                  label="Offer Name"
                  variant="outlined"
                  error={!!errors.offerName}
                  helperText={errors.offerName && errors.offerName.message}
                />
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} className={clsx([classes.drawerItems])}>
          <Controller
            name="pricingType"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth FormLabel="Pricing Type">
                <TextField
                  id="pricingType"
                  label="Pricing Type"
                  select
                  {...field}
                  data-value={field?.value}
                  className={clsx("pricingType", classes.flexInput)}
                  variant="outlined"
                  error={!!errors.pricingType}
                  helperText={errors.pricingType && errors.pricingType.message}
                >
                  {Object.entries(POE_PRICING_TYPE)?.map((elment) => {
                    if (elment[0] != "Unset") {
                      return (
                        <MenuItem
                          key={elment[0]}
                          id={elment[0]}
                          value={elment[0]}
                          name="pricingTypeList"
                        >
                          {elment[1].trim()}
                        </MenuItem>
                      );
                    }
                  })}
                </TextField>
              </FormControl>
            )}
          />
        </Grid>
        {displayFee === true && (
          <Grid item xs={12} sm={12} className={clsx([classes.drawerItems])}>
            <Controller
              name="fixFee"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth FormLabel="Fee">
                  <TextField
                    {...field}
                    id="fixFee"
                    label="Fee"
                    type="number"
                    InputProps={{
                      inputProps: {
                        min: 0,
                        step: 0.01,
                      },
                      inputMode: "numeric",
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    className={clsx("fixFee", classes.flexInput)}
                    variant="outlined"
                    error={!!errors.fixFee}
                    helperText={errors.fixFee && errors.fixFee.message}
                  />
                </FormControl>
              )}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={12} className={clsx([classes.drawerItems])}>
          <Controller
            name="duration"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth FormLabel="Duration">
                <DurationEntry
                  label={"Duration"}
                  name="duration"
                  value={field?.value}
                  onChange={(name, seconds) => {
                    field.onChange(seconds);
                  }}
                  variant="outlined"
                />
              </FormControl>
            )}
          />
          {errors?.duration && (
            <div className={clsx([classes.selectError])}>
              {errors?.duration?.message}
            </div>
          )}
        </Grid>
        <Grid item xs={12} sm={12} className={clsx([classes.drawerItems])}>
          <Controller
            name="rateID"
            control={control}
            render={({ field }) => (
              <Tooltip
                arrow
                title="This rate will be used for any over stay fee calculation as well as for the initial Pay on Entry fee when pricing type is set to Use Rate."
              >
                <FormControl fullWidth FormLabel="Rate">
                  <TextField
                    id="rate"
                    label="Rate"
                    {...field}
                    data-value={field?.value}
                    className={clsx([classes.rateID])}
                    variant="outlined"
                    select
                    error={!!errors.rateID}
                    helperText={errors.rateID && errors.rateID.message}
                  >
                    {availableRates != null &&
                      availableRates.map((row) => (
                        <MenuItem
                          key={row.rateID}
                          value={row.rateID}
                          id={row.name}
                        >
                          {row.name}
                        </MenuItem>
                      ))}
                  </TextField>
                </FormControl>
              </Tooltip>
            )}
          />
        </Grid>

        {displayFee === true && (
          <Grid item xs={12} sm={12} className={clsx([classes.drawerItems])}>
            <Controller
              name="useAdjustableFee"
              control={control}
              defaultValue={false}
              render={({ field }) => (
                <FormControlLabel
                  labelPlacement="end"
                  label="Adjustable Fee"
                  control={
                    <Switch
                      {...field}
                      id="useAdjustableFee"
                      color="primary"
                      name="useAdjustableFee"
                      checked={field.value}
                      data-checked={field.value}
                      onChange={e => {
                        field.onChange(e.target.checked)
                      }}
                    />
                  }
                />
              )}
            />
          </Grid>
        )}

        {payOnEntryImages && (
          <Grid item xs={12} sm={12} className={clsx([classes.drawerItems])}>
            <FormControl fullWidth FormLabel="Image">
              <POEBannerImage
                offerID={offer.offerID}
                imageHash={offer.imageHash}
                handleNewFile={handleNewParam}
              />
            </FormControl>
          </Grid>
        )}
        {offer.offerID && payOnEntryRevealSchedule && (
          <>
            <Grid 
              className={clsx([classes.drawerItems])}
              item 
              xs={12}
            >
              <Typography 
                variant='h6' 
                component='span'
              >
                Reveal Schedule
              </Typography>
            </Grid>
            <Grid 
              className={clsx([classes.drawerItems])}
              item 
              xs={12}
            >
              <Button
                onClick={() => setIsOfferScheduleDrawerOpen(true)}
                variant='contained'
                className={clsx("change-schedule")}
              >
                Change
              </Button>
              <PayOnEntryOfferSchedule 
                isOpen={isOfferScheduleDrawerOpen}
                offer={offer}
                handleCancel={() => { setIsOfferScheduleDrawerOpen(false);}}
              />
            </Grid>
          </>
        )}
      </Grid>
      <Divider></Divider>
      <Grid container className={clsx([classes.footerContainer])}>
        <Grid item xs={12} sm={12} className={clsx([classes.drawerItems])}>
          <Button
            className={clsx("cancelBtn", classes.btnControl)}
            name="cancel"
            variant="contained"
            onClick={handleCancel}
            disabled={isSubmitting}
          >
            Close
          </Button>
          <Button
            color="primary"
            name="submit"
            type="submit"
            variant="contained"
            className={clsx("saveBtn", classes.btnControl)}
            onClick={handleSubmit(onSubmit)}
            disabled={isSubmitting}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
export default PayOnEntryOfferForm;
