import React from 'react'
import clsx from 'clsx';
import { useStyles } from "./styles";
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleXmark,
} from '@fortawesome/free-solid-svg-icons';

const PayOnEntryOfferScheduleDialog = ({
  isOpen,
  title,
  confirmButtonText,
  confirmButtonIcon,
  onConfirm,
  isConfirmDisabled,
  onCancel,
  isLoading,
  children,
  ...rest
}) => {
  const classes = useStyles();

  const DialogActions = withStyles((theme) => ({
    root: {
      paddingTop: theme.spacing(2),
      paddingRight: theme.spacing(3),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(3)
    }
  }))(MuiDialogActions)

  return (
    <Dialog
      classes={clsx([classes.paper])}
      className={clsx('add-schedule', 'dialog', 'dialog-confirmation')}
      data-testid='poe-offer-schedule-dialog'
      open={isOpen}
      onClose={onCancel}
      {...rest}
    >
      <DialogTitle>
        {title}
      </DialogTitle>
      <DialogContent>
        {children}
      </DialogContent>
      <DialogActions>
        <Button
          className={clsx('confirm', 'ok')}
          color='primary'
          data-testid='dialog-confirm-button'
          onClick={onConfirm}
          startIcon={isLoading ? <CircularProgress color='inherit' size={20} /> : confirmButtonIcon}
          variant='contained'
          disabled={isLoading || isConfirmDisabled}
        >
          {confirmButtonText}
        </Button>
        <Button
          className={clsx('cancel')}
          data-testid='dialog-cancel-button'
          onClick={onCancel}
          startIcon={<FontAwesomeIcon icon={faCircleXmark} />}
          variant='contained'
          disabled={isLoading}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PayOnEntryOfferScheduleDialog;