import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  peripheralLabel: {
    fontSize: '1rem',
    color: theme.palette.primary.dark,
  },
  peripheralDivider: {
    marginBottom: '15px'
  },
  peripheralGrid: {
    width: "100%",
    margin: theme.spacing(1)
  },
  peripheralBox: {
    padding: '10px',
    height: "calc(100vh - 100px)",
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  peripheralLine: {
    marginTop: theme.spacing(1),
  },
  peripheralSwitch: {
    marginTop: "4px",
    float: "right",
  },
  peripheralDetail: {
    fontSize: '0.6rem',
    whiteSpace: "nowrap",
    width: '90%',
    overflow: 'hidden',
  },
  peripheralsDrawer: {
    display: "inline-block",
    [theme.breakpoints.up('sm')]: {
      width: "500px",
      flexShrink: 0,
    },
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      flexShrink: 0,
    },
  },
  cancelBtn: {
    margin: theme.spacing(2),
    float: "right",
    right: '10px',
  },
  peripheralDividerFixed: {
    marginBottom: '10px',
    marginRight: '20px',
    marginLeft: '20px',
  },
  drawerDetailConfiguration: {
    [theme.breakpoints.up('sm')]: {
      width: "500px",
      flexShrink: 0,
    },
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      flexShrink: 0,
    },
  },
  headerContainerSpacing: {
    marginTop: '15px'
  },
  peripheralIcon: {
    paddingLeft: "10%"
  },
  addButton: {
    float: "right",
  },
}));