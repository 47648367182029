import React, { useState, useEffect } from "react";
import { useStyles } from "./styles";
import useHubContext from "../../../../../../hooks/useHubContext";
import clsx from "clsx";
import { FormControlLabel, Grid, LinearProgress, Switch, Button } from "@material-ui/core";
import { useEnqueueSnackbar } from "../../../../../../hooks/useEnqueueSnackbar";
import {
  MONEY_INVENTORY_REQUEST_TOPIC,
  MONEY_INVENTORY_TOPIC,
  PORTAL_TRIGGER
} from "../../../../../../constants";
import Typography from "@material-ui/core/Typography";
import CheckIcon from "@material-ui/icons/Check";

export const BNRAccepted = ({ entityid, deviceService, denomsAcceptedStarter, override }) => {
  // Declarations
  const classes = useStyles();
  const { portalHub } = useHubContext();
  const [denominationsAccepted, setDenominationsAccepted] = useState(denomsAcceptedStarter);
  const [isFetchingAcceptedDenoms, setIsFetchingAcceptedDenoms] = useState(override !== false);
  const enqueueSnackbar = useEnqueueSnackbar();

  //Functions
  const handleDenominationChange = (denomination, index) => {
    const tempDenominations = [...denominationsAccepted];
    tempDenominations[index].Enabled = !tempDenominations[index].Enabled;
    setDenominationsAccepted([...tempDenominations]);
  }

  const handleDenominationSubmit = async (denomination, index) => {
    setIsFetchingAcceptedDenoms(true);
    try {
      const config = {
        EntityID: entityid,
        Denominations: denominationsAccepted,
        Action: "ConfigureAcceptedDenominations"
      }
      const result = await deviceService.configureBNR(config);
      if (result.status === 200) {
        enqueueSnackbar("Successfully sent message to accepted denominations", {
          variant: "success", tag: "configureAcceptedDenomSuccess"
        });
      }
    } catch (err) {
      enqueueSnackbar("Failed to send message for accepted denominations", {
        variant: "error", tag: "configureAcceptedDenomErr"
      });
    }
  };

  useEffect(() => {
    portalHub.subscribe(
      `${entityid}_${MONEY_INVENTORY_TOPIC}`,
      (message) => {
        const data = JSON.parse(message);
        if (data.Action === "AcceptedDenominationsResponse") {
          const messageData = JSON.parse(data.Message);
          setDenominationsAccepted(messageData.denominations);
          setIsFetchingAcceptedDenoms(false);
        } else if (data.Action === "AcceptedDenominationErrorResponse") {
          const errorData = JSON.parse(data.Message);
          setIsFetchingAcceptedDenoms(false);
          enqueueSnackbar("Failed to update accepted denominations", {
            variant: "error", tag: "configureAcceptedDenomErr"
          });
        }
      }
    );
    setTimeout(() => {
      portalHub.invoke(PORTAL_TRIGGER, {
        entityID: entityid,
        topic: MONEY_INVENTORY_REQUEST_TOPIC,
        method: "",
        action: "AcceptedDenominationsRequest",
        message: "",
      });
    }, 2000);

    return () => {
      portalHub.unsubscribe(`${entityid}_${MONEY_INVENTORY_TOPIC}`);
    };
  }, [portalHub.isOpen, portalHub.isConnected]);

  // DOM
  return (
    <Grid container spacing={1} className={classes.denomGrid}>
      <Typography className={classes.denomAcceptedTitle}>Denominations Accepted</Typography>
      {isFetchingAcceptedDenoms &&
        <Grid item xs={12}>
          <LinearProgress />
        </Grid>
      }
      {denominationsAccepted?.sort((a, b) => a.Amount - b.Amount).map((row, index) =>
        <>
          <Grid item
            xs={8}
            className={
              clsx(["denomination", row.Amount, classes.denomLabel])
            }
          >
            {`$${row.Amount}`}
          </Grid>
          <Grid data-tag="activeGrid" item xs={4}>
            <FormControlLabel
              labelPlacement="end"
              control={
                <Switch
                  className={clsx(["enabled-switch", (row.Enabled === true) ? "enabled" : "disabled" ])}
                  color="primary"
                  name="Enabled"
                  checked={row.Enabled}
                  onChange={() => handleDenominationChange(row.Amount, index)}
                />
              }
              label={row.Enabled ? 'Enabled' : 'Disabled'}
            />
          </Grid>
        </>
      )}
      <Button
        data-testid="submit-accepted-denominations"
        className={clsx(["submitBtn", classes.submitBtn])}
        onClick={handleDenominationSubmit}
        variant="contained"
        color="primary"
        disabled={isFetchingAcceptedDenoms}
        startIcon={<CheckIcon />}
      >
        Save
      </Button>
    </Grid>
  )
};

export default BNRAccepted;
