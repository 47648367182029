import React, { useState, useEffect } from "react";
import { useStyles } from "./styles";
import MenuItem from "@material-ui/core/MenuItem";
import SearchBar from "../SearchBar";

const PropertyMenuSearch = ({
  onSearchChange,
  facilities
}) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  const stopImmediatePropagation = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const onSeachTextChange = React.useCallback(
    (val) => {
      if (onSearchChange) {
        onSearchChange(val);
      }
      setIsLoading(true);
    },
    [onSearchChange, setIsLoading]
  );

  useEffect(() => {
    setIsLoading(false);
  }, [facilities]);

  return (
    <MenuItem
      onClickCapture={stopImmediatePropagation}
      onKeyDown={(e) => e.stopPropagation()}
      selected={false}
      className={classes.searchContainer}
    >
      <SearchBar
        data-id="facility-Searchbar"
        delay={250}
        onChange={onSeachTextChange}
        isLoading={isLoading}
      />
    </MenuItem>
  );
};

export default PropertyMenuSearch;
