import React, { useEffect, useReducer } from 'react';
import { useStyles } from "./styles";
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
import { DateTimePicker } from '@material-ui/pickers';
import PayOnEntryOfferScheduleDialog from '../PayOnEntryOfferScheduleDialog/PayOnEntryOfferScheduleDialog';
import { usePOEOfferSchedule } from '../../../hooks/usePOEOfferSchedule';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faFloppyDisk,
  faPen,
  faPlus,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import {
  faCalendar
} from '@fortawesome/free-regular-svg-icons';
import { initialState, reducer } from './reducer';
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import moment from 'moment';

const PayOnEntryOfferRevealSchedule = ({ offerId }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const classes = useStyles();
  const { 
    poeOfferSchedules, 
    poeOfferSchedulesLoading, 
    poeOfferSchedulesError,
    timeZone,
    createPOEOfferSchedule, 
    updatePOEOfferSchedule, 
    deletePOEOfferSchedule 
  } = usePOEOfferSchedule(offerId);
  const enqueueSnackbar = useEnqueueSnackbar();

  const onAdd = () => {
    dispatch({ type: 'SET_ADD_SCHEDULE', payload: {
      addEditDialog: {
        dialogTitle: 'Add Schedule',
        isDialogOpen: true
      }
    }})
  }

  const onEdit = (scheduleId) => {
    const offerSchedule = poeOfferSchedules.events.find((event) => event.id === scheduleId);

    dispatch({ type: 'SET_EDIT_SCHEDULE', payload: {
      selectedScheduleId: scheduleId,
      addEditDialog: {
        dialogTitle: 'Edit Schedule',
        isDialogOpen: true
      },
      start: offerSchedule.start,
      end: offerSchedule.end
    }})
  }

  const onStartDateChanged = (date) => {
    dispatch({ type: 'SET_START_DATE', payload: {
      start: date.toISOString(),
      startError: state.end && moment(date) > moment(state.end) ? 'Start date and time must be less than end date and time' : undefined
    }})
  }

  const onEndDateChanged = (date) => {
    dispatch({ type: 'SET_END_DATE', payload: {
      end: date.toISOString(),
      endError: state.start && moment(date) < moment(state.start) ? 'End date and time must be greater than start date and time' : undefined
    }})
  }

  const onSave = async () => {
    try {
      dispatch({ type: 'SET_ADDING_EDITING_SCHEDULE', payload: true })

      if (!state.startError && !state.endError) {
        let offerSchedule = {
          OfferID: offerId,
          Start: state.start,
          End: state.end,
          Recurrence: false
        }

        if (state.selectedScheduleId) {
          offerSchedule = {
            ...offerSchedule,
            OfferScheduleID: state.selectedScheduleId
          }

          await updatePOEOfferSchedule(offerSchedule);
        } else {
          await createPOEOfferSchedule(offerSchedule)
        }
      } 
    } catch (error) {
      const errorMessage = state.selectedScheduleId ? 'Failed to update schedule' : 'Failed to create schedule';

      enqueueSnackbar(errorMessage, {
        variant: "error",
        tag: "FailedToCreateUpdateOfferSchedule"
      });
    } finally {
      if (!state.startError && !state.endError) {
        dispatch({
          type: 'SET_ADD_EDIT_COMPLETE',
          payload: {
            selectedScheduleId: undefined,
            addEditDialog: {
              isDialogOpen: false,
              isLoading: false,
            },
            start: null,
            startError: undefined,
            end: null,
            endError: undefined
          }
        })
      }
    }
  }

  const onCancelAddEdit = ()  => {
    dispatch({ type: 'SET_CANCEL_ADD_EDIT', payload: { isDialogOpen: false }})
  }

  const onConfirmDelete = async () => {
    try {
      dispatch({ type: 'SET_DELETING', payload: true });

      await deletePOEOfferSchedule(state.selectedScheduleId)
    } catch (error) {
      enqueueSnackbar("Failed to delete schedule.", {
        variant: "error",
        tag: "FailedToDeleteOfferSchedule"
      });
    } finally {
      dispatch({ 
        type: 'SET_DELETE_COMPLETE', 
        payload: {
          selectedScheduleId: undefined,
          deleteDialog: {
            isDialogOpen: false,
            isDialogLoading: false
          }
        }
      })
    }
  }

  const onCancelDelete = () => {
    dispatch({ 
      type: 'SET_CANCEL_DELETE', 
      payload: {
        selectedScheduleId: undefined,
        isDialogOpen: false
      }
    })
  }

  const onDelete = (scheduleId) => {
    dispatch({ 
      type: 'SET_CONFIRM_DELETE', 
      payload: {
        selectedScheduleId: scheduleId,
        deleteDialog: {
          dialogTitle: 'Delete Offer Schedule',
          isDialogOpen: true
        }
      }
    })
  }

  useEffect(() => {
    if (poeOfferSchedulesError) {
      enqueueSnackbar(poeOfferSchedulesError, {
        variant: "error",
        tag: "FailedToGetOfferSchedules"
      });
    }
  }, [poeOfferSchedulesError])

  return (
    <>
      <Grid
        className={classes.container}
        container
        spacing={2}
        alignItems='center'
      >
        <Grid
          className={classes.title}
          container
          item
          xs={12}
        >
          <Grid
            item
            xs={10}
          >
            <Typography 
              
              component='h4'
              variant='h5'
            >
              Scheduled
            </Typography>
          </Grid>
          <Grid
            item
            xs={2}
          >
            <Button
              className='add-schedule'
              data-testid='add-schedule-button'
              onClick={onAdd}
              startIcon={<FontAwesomeIcon icon={faPlus} />}
              variant='outlined'
            >
              Add
            </Button>
          </Grid>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1" className={classes.subTitle}>
              Scheduled dates are specific dates and times the event will go online in the calendar year.
            </Typography>
          </Grid>
        </Grid>
        <Grid
          className={classes.cardContainer}
          container
          item
          xs={12}
          spacing={1}
        >
          {poeOfferSchedulesLoading && [...Array(3)].map((value, index) => {
            return (
              <>
                <Grid
                  item
                  xs={12}
                >
                  <Skeleton
                    variant='rect'
                    width='493px'
                    height='116px'
                    data-testid='poe-offer-schedule-skeleton'
                  />
                </Grid>
              </>
            )
          })}
          {!poeOfferSchedulesLoading && poeOfferSchedules?.events?.length > 0 && poeOfferSchedules.events.map((schedule) => {
            return (
              <Grid
                item
                xs={12}
                key={schedule.id}
              >
                <Card
                  className={clsx([classes.card], 'poe-offer-schedule')}
                  data-testid='poe-offer-schedule-card'
                  variant='outlined'
                >
                  <CardContent
                    className={clsx([classes.cardContentRoot])}
                  >
                    <Grid
                      container
                      item
                      xs={12}
                      alignItems='center'
                    >
                      <Grid
                        item
                        xs={10}
                      >
                        <Typography
                          className={clsx([classes.cardContentText])}
                          component='span'
                          variant='subtitle1'
                          data-testid={'poe-offer-schedule-start-span'}
                        >
                          {moment.tz(schedule.start, timeZone).format('dddd, MMMM D, YYYY [at] h:mm a')}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={2}
                      >
                        <Grid
                          container
                          justify='flex-end'
                          spacing={2}
                        >
                          <Grid item>
                            <IconButton
                              className={clsx([classes.cardContent])}
                              data-testid='poe-edit-schedule-button'
                              onClick={() => onEdit(schedule.id)}
                            >
                              <FontAwesomeIcon icon={faPen} />
                            </IconButton>
                          </Grid>
                          <Grid item>
                            <IconButton
                              className={clsx([classes.cardContent])}
                              data-testid='poe-delete-schedule-button'
                              onClick={() => onDelete(schedule.id)}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                      >
                        <Typography
                          component='span'
                          variant='subtitle1'
                        >
                          {`to `}
                        </Typography>
                        <Typography
                          className={clsx([classes.cardContentText])}
                          component='span'
                          variant='subtitle1'
                          data-testid={'poe-offer-schedule-end-span'}
                        >
                          {moment.tz(schedule.end, timeZone).format('dddd, MMMM D, YYYY [at] h:mm a')}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            )
          })}
          {!poeOfferSchedulesLoading && poeOfferSchedules?.events?.length === 0 &&
            <Grid
              item
              xs={12}
            >
              <Typography 
                variant='subtitle1' 
                component='span'
                data-testid='poe-offer-schedule-empty-text'
              >
                There are no pay on entry offer schedules.
              </Typography>
            </Grid>
          }
        </Grid>

      <PayOnEntryOfferScheduleDialog
        isOpen={state.addEditDialog.isDialogOpen}
        title={state.addEditDialog.dialogTitle}
        confirmButtonText='Save'
        confirmButtonIcon={<FontAwesomeIcon icon={faFloppyDisk} />}
        onConfirm={onSave}
        isConfirmDisabled={state.startError || state.endError}
        onCancel={onCancelAddEdit}
        isLoading={state.addEditDialog.isDialogLoading}
      >
        <Grid
          container
          spacing={2}
          alignItems='center'
        >
          <Grid
            item
            xs={2}
          >
            <Typography variant='body1'>Start:</Typography>
          </Grid>
          <Grid
            item
            xs={10}
          >
            <DateTimePicker
              className={clsx('startdate')}
              value={state.start}
              inputVariant='outlined'
              disablePast={true}
              maxDate={state.end ? state.end : new Date('2100-01-01')}
              size='small'
              fullWidth
              error={state.startError}
              helperText={state.startError ? state.startError : null}
              InputProps={{
                endAdornment: <FontAwesomeIcon icon={faCalendar} />,
                'data-testid': 'poe-offer-schedule-startdate'
              }}
              format="MM/DD/YYYY hh:mm a"
              onChange={onStartDateChanged}
            />
          </Grid>
          <Grid
            item
            xs={2}
          >
            <Typography variant='body1'>End:</Typography>
          </Grid>
          <Grid
            item
            xs={10}
          >
            <DateTimePicker
              className={clsx('enddate')}
              value={state.end}
              inputVariant='outlined'
              disablePast={true}
              minDate={state.start ? state.start : new Date()}
              size='small'
              fullWidth
              error={state.endError}
              helperText={state.endError ? state.endError : null}
              InputProps={{
                endAdornment: <FontAwesomeIcon icon={faCalendar} />,
                'data-testid': 'poe-offer-schedule-enddate'
              }}
              format="MM/DD/YYYY hh:mm a"
              onChange={onEndDateChanged}
            />
          </Grid>
        </Grid>
      </PayOnEntryOfferScheduleDialog>
      <PayOnEntryOfferScheduleDialog
        isOpen={state.deleteDialog.isDialogOpen}
        title={state.deleteDialog.dialogTitle}
        confirmButtonText='OK'
        confirmButtonIcon={<FontAwesomeIcon icon={faCheck} />}
        onConfirm={onConfirmDelete} 
        onCancel={onCancelDelete}
        isLoading={state.deleteDialog.isDialogLoading}
      >
        <DialogContentText>
          Are you sure you want to delete the offer schedule?
        </DialogContentText>
      </PayOnEntryOfferScheduleDialog>
    </>
  )
}

export default PayOnEntryOfferRevealSchedule;