import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  title: {
    margin: theme.spacing(1),
    marginBottom: 3
  },
  doorStrike: {
    padding: theme.spacing(1),
  },
  bnrConfiguration: {
    padding: theme.spacing(2),
  },
	gateConfiguration: {
		padding: theme.spacing(1),
	},
  proxReaderConfiguration: {
		padding: theme.spacing(1),
	},
  drawerItems: {
    padding: theme.spacing(1),
  },
  gatewayDropDown : {
    "& :nth-child(1)": {
      marginBottom: 0
    },
    "& .MuiFormControl-root": {
      width: "100%",
    }
  },
    

  btnControl: {
    margin: theme.spacing(2),
    float: "right",
    right: '10px',
  },
  flexInput: {
    flexGrow: "1"
  },
  headerContainerSpacing: {
    marginTop: '15px'
  },
  footerContainer: {
    paddingTop: '50px',
    padding: '10px',
  },
  configurationContainer: {
    paddingTop: '30px',
    padding: theme.spacing(1),
  },
  peripheralDividerBottom: {
    marginBottom: '15px'
  },
  peripheralDividerTop: {
    marginTop: '15px'
  },
  ConfigurationTitle: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '20px',
    color: theme.palette.primary.light,
  },
  peripheralsDrawer: {
    display: "inline-block",
    [theme.breakpoints.up('sm')]: {
      width: "500px",
      flexShrink: 0,
    },
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      flexShrink: 0,
    },
  },
  peripheralBox: {
    padding: '10px',
    height: "calc(100vh - 100px)",
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  drawerDetailConfiguration: {
    [theme.breakpoints.up('sm')]: {
      width: "500px",
      flexShrink: 0,
    },
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      flexShrink: 0,
    },
  },
  peripheralDividerFixed: {
    marginBottom: '10px',
    marginRight: '20px',
    marginLeft: '20px',
  },
  btnDelete: {
    margin: theme.spacing(1),
    float: "right",
  },
}));
