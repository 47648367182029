import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  bannerimage: {
    marginTop:10
  },
  centerContentColumn: {
    textAlign: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
  },
  errorMessage: {
    color: "#e70022",
    paddingLeft: theme.spacing(2)
  },
  noBannerMessage: {
    paddingTop: theme.spacing(2),
    fontSize: "1.2rem"
  },
  loadingSpinner: {
    textAlign: "center",
    marginTop:10
  },
  noDataMessage: {
    paddingTop: theme.spacing(2),
    fontSize: "1.5rem"
  },
}));