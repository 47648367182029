export const initialState = {
  selectedScheduleId: undefined,
  start: null,
  startError: undefined,
  end: null,
  endError: undefined,
  addEditDialog: {
    dialogTitle: '',
    isDialogOpen: false,
    isDialogLoading: false
  },
  deleteDialog: {
    dialogTitle: 'Delete Schedule',
    isDialogOpen: false,
    isDialogLoading: false
  }
  
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ADD_SCHEDULE': {
      return {
        ...state,
        addEditDialog: {
          ...state.addEditDialog,
          dialogTitle: action.payload.addEditDialog.dialogTitle,
          isDialogOpen: action.payload.addEditDialog.isDialogOpen,
        }
      }
    }
    case 'SET_EDIT_SCHEDULE': {
      return {
        ...state,
        selectedScheduleId: action.payload.selectedScheduleId,
        addEditDialog: {
          ...state.addEditDialog,
          dialogTitle: action.payload.addEditDialog.dialogTitle,
          isDialogOpen: action.payload.addEditDialog.isDialogOpen,
        },
        start: action.payload.start,
        end: action.payload.end
      }
    }
    case 'SET_START_DATE': {
      return {
        ...state,
        start: action.payload.start,
        startError: action.payload.startError
      }
    }
    case 'SET_END_DATE': {
      return {
        ...state,
        end: action.payload.end,
        endError: action.payload.endError
      }
    }
    case 'SET_ADDING_EDITING_SCHEDULE': {
      return {
        ...state,
        addEditDialog: {
          ...state.addEditDialog,
          isDialogLoading: action.payload
        }
      }
    }
    case 'SET_ADD_EDIT_COMPLETE': {
      return {
        ...state,
        selectedScheduleId: undefined,
        addEditDialog: {
          ...state.addEditDialog,
          isDialogOpen: action.payload.isDialogOpen,
          isDialogLoading: action.payload.isLoading,
        },
        start: action.payload.start,
        end: action.payload.end
      }
    }
    case 'SET_CANCEL_ADD_EDIT': {
      return {
        ...state,
        addEditDialog: {
          ...state.addEditDialog,
          isDialogOpen: action.payload.isDialogOpen
        },
        start: null,
        startError: undefined,
        end: null,
        endError: undefined
      }
    }
    case 'SET_CONFIRM_DELETE': {
      return {
        ...state,
        selectedScheduleId: action.payload.selectedScheduleId,
        deleteDialog: {
          ...state.deleteDialog,
          dialogTitle: action.payload.deleteDialog.dialogTitle,
          isDialogOpen: action.payload.deleteDialog.isDialogOpen,
        }
      }
    }
    case 'SET_DELETING': {
      return {
        ...state,
        deleteDialog: {
          ...state.deleteDialog,
          isDialogLoading: action.payload
        }
      }
    }
    case 'SET_DELETE_COMPLETE': {
      return {
        ...state,
        selectedScheduleId: action.payload.selectedScheduleId,
        deleteDialog: {
          ...state.deleteDialog,
          isDialogOpen: action.payload.isDialogOpen,
          isDialogLoading: action.payload.isDialotLoading,
        }
      }
    }
    case 'SET_CANCEL_DELETE': {
      return {
        ...state,
        selectedScheduleId: action.payload.selectedScheduleId,
        deleteDialog: {
          ...state.deleteDialog,
          isDialogOpen: action.payload.isDialogOpen
        }
      }
    }
    default: {
      return state;
    }
  }
}