import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useStyles } from "./styles";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import EmojiTransportationIcon from "@material-ui/icons/EmojiTransportation";
import { faWarehouseFull as FGIcon } from "@fortawesome/pro-duotone-svg-icons";
import ApartmentIcon from "@material-ui/icons/Apartment";
import PropertyMenuSearch from "./PropertyMenuSearchComponent";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import useCurrentFacility from "../../hooks/useCurrentFacility";
import StyledMenu, { StyledMenuItem } from "../Modals/StyledMenu";
import PortalHubFacilityChangePublisher from "./PortalHubFacilityChangePublisher";
import { ENTITY_TYPE } from "../../constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";

const PropertyMenu = ({
  isOpen,
  onClose,
  onPropertiesSelected: onFacilitiesSelected,
  properties: facilities,
  onSearchChange,
  autoSelectFirst,
  currentAnchorEl,
  listRef,
}) => {
  const classes = useStyles();
  const [selectedFacilities, setSelectedFacilities] = useState({});
  const [autoFirstItem, setAutoFirstItem] = useState(autoSelectFirst);
  const { facilityID } = useCurrentFacility();

  useEffect(() => {
    onFacilitiesSelected(
      JSON.stringify(selectedFacilities) === "{}"
        ? ""
        : mutateObjectToArray(selectedFacilities)
    );
  }, [selectedFacilities]);

  const handleFacilityChange = (isChecked, facilityID, propertyName) => {
    setAutoFirstItem(false);
    if (isChecked) setSelectedFacilities({ [facilityID]: propertyName });
  };

  const mutateObjectToArray = (object) => {
    const arr = [];
    Object.keys(object).forEach((key) => {
      arr.push({ facilityID: key, name: object[key] });
    });
    return arr;
  };

  return (
    <div ref={listRef}>
      <StyledMenu
        id="customized-menu"
        anchorEl={currentAnchorEl}
        keepMounted
        open={isOpen}
        onClose={() => onClose()}
      >
        <PropertyMenuSearch
          onSearchChange={onSearchChange}
          facilities={facilities}
        ></PropertyMenuSearch>
        {facilities?.length > 0 &&
          facilities.map((x) => {
            return (
              <div key={x.id}>
                <Typography
                  color="primary"
                  className={classes.facilityParentSpacing}
                  variant="h6"
                >
                  <ApartmentIcon
                    className={classes.facilityParentIconSpacing}
                    fontSize="small"
                  />
                  {x.name}
                </Typography>
                <ChildMenu 
                  parentTree={x}
                  handleFacilityChange={handleFacilityChange}
                  autoFirstItem={autoFirstItem}
                  facilityID={facilityID}
                  depth={1}
                />
              </div>
            );
          })}
      </StyledMenu>
      <PortalHubFacilityChangePublisher></PortalHubFacilityChangePublisher>
    </div>
  );
};

const ChildMenu = ({
  parentTree,
  handleFacilityChange,
  autoFirstItem,
  facilityID,
  depth,
  ...props
}) => {
  const classes = useStyles();
 return (parentTree.children?.length > 0 && (
  _.orderBy(parentTree.children, ["name"], ["asc"]).map((i, index) => (
      <>
      {i.entityType === ENTITY_TYPE.FacilityGroup && (
        <>
          <div className={classes.facilityGroupSpacing}
            data-facility-group-id={i.id}
            data-facility-group-name={i.name}>
            <Typography className={classes.facilityGroupLabel} color="primary">
              <div className={classes.facilityGroupIconContainer}>
                <FontAwesomeIcon icon={FGIcon} fontSize="small" />
              </div>
              {i.name}  
            </Typography>
          </div>
          {i.children && (
            <ChildMenu
              parentTree={i}
              handleFacilityChange={handleFacilityChange}
              autoFirstItem={autoFirstItem}
              facilityID={facilityID}
              depth={depth + 1}
            />   
          )}
        </>
      )}
      {i.entityType != ENTITY_TYPE.FacilityGroup && (
          <StyledMenuItem
          className={clsx("facility-selection", depth > 1 ? classes.nestedFacility : classes.standAloneFacility)}
          data-facility-id={i.id}
          data-facility-name={i.name}
          key={i.id}
          onClick={(isChecked) => {
            handleFacilityChange(isChecked, i.id, i.name);
          }}
          selected={
            (index === 0 && autoFirstItem === true) ||
            i.id === facilityID
          }
        >
          <ListItemIcon>
            <EmojiTransportationIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={i.name} />
        </StyledMenuItem>
        )}
        </>
  )))
 )
};

PropertyMenu.defaultProps = {
  properties: [],
  autoSelectFirst: false,
};

PropertyMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onPropertiesSelected: PropTypes.func,
  onSearchChange: PropTypes.func,
  autoSelectFirst: PropTypes.bool,
};

export default PropertyMenu;
