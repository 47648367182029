import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
  root: {},
  button: {
    textTransform: "none",
    color: "inherit",
    marginRight: "20px",
    [theme.breakpoints.down("md")]: {
      marginRight: "-20px"
    },
    "&& svg": {
      [theme.breakpoints.down("md")]: {
        fontSize: "40px"
      }
    }
  }
}));
