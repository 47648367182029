import React, { useState, useEffect } from "react";
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useStyles } from "./styles.js";
import { useEnqueueSnackbar } from "../../../../../hooks/useEnqueueSnackbar";
import apiClient from "../../../../../auth/apiClient";
import GPIOService from "../../../../../services/GPIOService";

const gpioService = new GPIOService(apiClient);

const DoorStrikeSettings = ({ peripheral, updatePeripheralSettings }) => {
  const classes = useStyles();
  const enqueueSnackbar = useEnqueueSnackbar();
  const [settings, setSettings] = useState({
    ioMappingID: peripheral?.settings?.ioMappingID,
    friendlyName: peripheral?.settings?.friendlyName,
    vendStrikeDuration: peripheral?.settings?.vendStrikeDuration ?? 5000
  });
  const [valueError, setValueError] = useState(peripheral?.settings?.vendStrikeDuration < 1);
  const defaultIOMapping = {
    friendlyName: "None",
    ioMappingID: 0
  };
  const defaultIOMappingsArray = [defaultIOMapping];
  const [GPIOMappings, setGPIOMappings] = useState(defaultIOMappingsArray);

  const GetGPIOMappingInfo = async () => {
    try {
      var result = await gpioService.getIOMappings(peripheral.entityID);
      result.data.unshift(defaultIOMapping);
      setGPIOMappings(result.data);
    } catch (error) {
      setGPIOMappings(defaultIOMappingsArray);
      enqueueSnackbar("Failed to get GPIOMappings", {
        variant: "error",
        tag: "getGPIOError"
      });
    }
  }

  useEffect(() => {
    GetGPIOMappingInfo();
  }, [peripheral.entityID]);

  const handleKeyPress = (e) => {
    if (e.key === "Escape") {
      e.target.blur();
    }
  }

  const onDropDownChange = (settingName, value) => {
    let currentSettings = {
      ...settings,
      [settingName]: value,
      friendlyName: GPIOMappings?.find(x => x.ioMappingID === value)?.friendlyName
    }
    setSettings(currentSettings);
    saveSettings(currentSettings);
  }

  const onVendDurationChange = (settingName, value) => {
    if (value < 1 || /[a-zA-Z]/.test(value)) setValueError(true);
    else
      if (valueError) setValueError(false);

    let currentSettings = {
      ...settings,
      [settingName]: value,
    }

    setSettings(currentSettings);
  }

  const saveSettings = (currentSettings) => {
    if (valueError) return;

    updatePeripheralSettings(currentSettings, peripheral.peripheralID);
  };

  return (
    <>
      <Grid>
        <FormControl fullWidth FormLabel="Board Type" variant="outlined">
          <InputLabel id="gpioLabel" className={clsx("dropdown-label")}>Select a GPIO mapping</InputLabel>
          <Select
            displayEmpty
            data-id="gpio-mapping-dropddown"
            className={clsx("board-dropdown")}
            variant="outlined"
            fullWidth
            labelId="gpioLabel"
            label="Select a GPIO mapping"
            inputProps={{
              id: "board-dropdown",
              "data-testid": "gpio-mapping-dropddown"
            }}
            onChange={(e) => onDropDownChange("ioMappingID", e.target.value)}
            value={settings?.ioMappingID ?? 0}
          >
            {GPIOMappings?.map((row) => {
              return (
                <MenuItem
                  key={row.ioMappingID}
                  value={row.ioMappingID}
                  className={clsx(`${row.friendlyName}-menu-item`)}
                  name={row.friendlyName}
                >
                  {row.friendlyName}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid className={classes.textFieldGrid}>
        <TextField
          className={clsx("vendStrikeDuration-textfield")}
          name="vendStrikeDuration"
          variant="outlined"
          type="number"
          label="Door strike vend duration"
          data-id="vend-strike-textfield"
          data-testid="vend-strike-textfield"
          onKeyDown={e => handleKeyPress(e)}
          helperText={valueError ? "Minimum value is 1 millisecond" : "in milliseconds"}
          value={settings?.vendStrikeDuration ?? 5000}
          onChange={e => onVendDurationChange("vendStrikeDuration", e.target.value)}
          onBlur={() => saveSettings(settings)}
          InputLabelProps={{
            shrink: true,
          }}
          error={valueError}
        />
      </Grid>

    </>
  );
}

export default DoorStrikeSettings;