import React, { useRef, useState, useEffect, useCallback, useReducer } from "react";
import PropTypes from "prop-types";
import { Button, Grid, FormControl, Typography, Tooltip } from "@material-ui/core";
import { useStyles } from "./styles";
import clsx from "clsx";
import apiClient from "../../../auth/apiClient";
import PayOnEntryService from "../../../services/PayOnEntryService";
import Loading from "../../Loading";
const fileTypesSupported = ["image/png", "image/svg+xml"];
const payonentryService = new PayOnEntryService(apiClient);

const initialState = {
  poeImage: null,
  errorToDisplay: ""
};

const poeBannerImageReducer = (state, { payload, type }) => {
  switch (type) {
    case "ON_VALUE_CHANGED":
      return { ...state, ...payload };
    default:
      return state;
  }
}

const POEBannerImage = ({ offerID, imageHash, handleNewFile }) => {
  const classes = useStyles();
  const inputElement = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const poeBannerImageData = useReducer(poeBannerImageReducer, initialState);
  const [values, dispatch] = poeBannerImageData;

  const handleStateChange = (payload) => {
    dispatch({
      type: "ON_VALUE_CHANGED",
      payload: { ...payload }
    })
  }

  useEffect(() => {
    getPOEImage();
  }, [offerID, imageHash]);

  const getPOEImage = useCallback(async () => {
    setIsLoading(true);

    if (!offerID || !imageHash) {
      setIsLoading(false);
      return;
    }

    await payonentryService
      .getImageByOfferId(offerID)
      .then((result) => {
        if (result !== null) {
          var reader = new FileReader();
          // This conversion is needed for svg file.
          // Otherwise there is no way to identify file type is svg (not xml).
          reader.readAsDataURL(new Blob([result.data], {type: result.data.type}));
          reader.onload = function (e) {
            handleStateChange({
              poeImage: e.target.result,
              errorToDisplay: ""
            })
          };
        }
      })
      .catch(err => 
        console.error(err)
      );

    setIsLoading(false);
  }, [offerID, imageHash]);

  const handleFileSelectedchange = (event) => {

    if (event.target.files.length > 0) {
      var file = event.target.files[0];
      var validType = fileTypesSupported.includes(event.target.files[0].type);
      var validSize = event.target.files[0].size > 0 && event.target.files[0].size < 1048576;

      if (!validType || !validSize) {
        if (!validSize) {
          handleStateChange({
            poeImage: null,
            errorToDisplay: "Invalid file size"
          })
        } else {
          handleStateChange({
            poeImage: null,
            errorToDisplay: "Invalid file type"
          })
        }
  
        handleNewFile({
          encodedImage: null,
          imageExtension: null
        });
        return;
      }

      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (e) {
        handleStateChange({
          poeImage: e.target.result,
          errorToDisplay: ""
        })
        handleNewFile({
          encodedImage: e.target.result.split(",")[1],
          imageExtension: convertFileType(file.type)
        });
      };
    }
  };

  const convertFileType = (type) => {
    // Need to convert type to follow what api is expecting
    switch (type) {
      case 'image/png':
        return 'png';
      case 'image/svg+xml':
        return 'svg';
      default:
        return null;
    }
  }

  return (
    <div>
      <Grid item xs={12} md={12}>
        <Tooltip
            arrow
            title="The image must be a .png or .svg format. Recommended image size: Width of 900 pixels, height 200 pixels. File size must be less than 1mb."
        >
          <Button
            fullWidth
            color="primary"
            variant="contained"
            onClick={() => inputElement.current.click()}
          >
            Choose banner image
          </Button>
        </Tooltip>
      </Grid>
      <input
        type="file"
        name="poeimagefile"
        id="poeimagefile"
        data-testid="poeimagefile"
        onChange={handleFileSelectedchange}
        ref={inputElement}
        accept="image/png, image/svg+xml"
        style={{ display: 'none' }}
      />
      {isLoading ? (
        <Loading className={clsx(classes.loadingSpinner)}/>) : (
        values.poeImage != null ? (
          <Grid item xs={12} md={12}>
            <FormControl fullWidth FormLabel="Banner Image">
              <img
                className={clsx("poe-banner-image", classes.bannerimage)}
                src={values.poeImage}
                alt="Failed to load the image"/>
            </FormControl>
          </Grid>
        ) : (
          <Grid item xs={12} md={12}>
            {values.errorToDisplay ? (
              <Typography
                className={clsx("image-error-message", classes.errorMessage)}>
                  {values.errorToDisplay}
              </Typography>) : (
              <Typography
                className={clsx("no-image-message",
                classes.centerContentColumn,
                classes.noBannerMessage)}>
                  No image has been associated for this offer
              </Typography>
            )}
          </Grid>
        )
      )}
    </div>
  );
};

POEBannerImage.defaultProps = {
  handleNewFile: () => { }
};

POEBannerImage.propTypes = {
  handleNewFile: PropTypes.func
};

export default POEBannerImage;