import React, { useEffect } from "react";
import useCurrentFacility from "../../hooks/useCurrentFacility";
import { SET_ENTITY_CONTEXT } from "../../constants";
import useHubContext from "../../hooks/useHubContext";

const PortalHubFacilityChangePublisher = () => {
  const { facilityID } = useCurrentFacility();
  const { Connected, portalHub } = useHubContext();

  const SendMessageAsync = async (entityId) => {
    try {
      await portalHub.invoke(SET_ENTITY_CONTEXT, {
        entityId: entityId,
      });
    } catch (error) {
      console.error("Couldn't set Entity Context in Hub", entityId);
    }
  };

  useEffect(() => {
    if (Connected) {
      SendMessageAsync(facilityID);
    }
  }, [facilityID, Connected]);
  return <></>;
};

export default PortalHubFacilityChangePublisher;
